<template>
  <div class="panel" :class="{'-loading': loading, '-drop': !small }">
    <div v-if="title" class="title">
      <h1>{{ title }}</h1>
      <slot name="extra-title" />
    </div>
    <div class="content" :class="{ small: !title || small }">
      <slot />
    </div>
    <slot name="footer" />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    loading: Boolean,
    small: Boolean,
  },
};
</script>

<style scoped lang="postcss">
@import '../styles';

.panel {
  &.-loading {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      display: block;
      z-index: 100;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(255, 255, 255, .8);
    }
  }

  &.-drop {
    @apply --panelDropShadow;
  }

  border-radius: var(--radius);
  background-color: var(--colorWhite);

  & .title {
    margin: 0;
    background-color: var(--colorSlate);
    padding: var(--spacingXS) var(--spacingMd);
    border-radius: var(--radius) var(--radius) 0 0;
    color: var(--colorWhite);

    & h1 {
      @apply --f6;

      margin: 0;
    }
  }

  & .content {
    /* padding: 2.6rem var(--spacingMd); */
    padding: var(--spacingMd);

    @media (--tablet) {
      padding: 3.4rem;
    }

    /* @media (--desktop) {
      padding: var(--spacingLg);
    } */

    & .small {
      padding: var(--spacingXS);
    }
  }
}
</style>
