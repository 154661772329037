<template>
  <div class="ui-gradient-loader">
    <div class="animation" :class="{ dark }"></div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },
})
export default class UIGradientLoader extends Vue {}
</script>

<style lang="postcss" scoped>
@import "../../styles";

.ui-gradient-loader {
  background-color: var(--colorCloud);
  box-shadow: var(--shadowSm);
  height: 5rem;

  & .animation {
    height: 100%;
    width: 100%;
    background: linear-gradient(
      45deg,
      var(--colorCasper) 40%,
      var(--colorCloud) 50%,
      var(--colorCasper) 60%,
      var(--colorCasper) 100%
    );
    background-size: 150% 100%;
    background-position-x: 180%;
    animation: GradientMove 1s ease infinite;

    &.dark {
      background: linear-gradient(
        45deg,
        #f0f3f3 40%,
        var(--colorCloud) 50%,
        #f0f3f3 60%,
        #f0f3f3 100%
      );
      background-size: 150% 100%;
      background-position-x: 180%;
      animation: GradientMove 1s ease infinite;
    }
  }
}
@keyframes GradientMove {
  0% {
    background-position-x: 180%;
  }
  100% {
    background-position-x: -80%;
  }
}
</style>
