<template>
  <div class="accept-tos">
    <div class="imagecontainer">
      <img alt="SpringboardVR" src="../assets/sbvr-dark-logo.svg">
    </div>
    <Panel>
      <template v-if="loading">
        <UIGradientLoader class="mb4" />
        <UIGradientLoader class="mb4" />
        <UIGradientLoader />
      </template>
      <div v-if="!loading" class="animated fadeIn">
        <div class="toscontent sbvr-standard" v-html="upcomingTosModal"></div>
        <div class="buttons">
          <UIButton v-if="showRemindLater" ghost @click="remindMeLater">{{ $t('Remind Me Later') }}</UIButton>
          <UIButton @click="accept">{{ $t('Accept') }}</UIButton>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import Panel from '../components/Panel';
import UIButton from '../components/ui/UIButton';
import UIGradientLoader from '../components/ui/UIGradientLoader';

@Component({
  components: {
    Panel,
    UIButton,
    UIGradientLoader,
  },
})
export default class UpcomingTermsOfServiceRoute extends Vue {
  upcomingTosModal = '';
  loading = true;

  get user () {
    return this.$store.getters.currentUser;
  }

  get showRemindLater () {
    if (this.user && this.user.upcomingAgreedAtRequiredBy) {
      return new Date().getTime() <= new Date(this.user.upcomingAgreedAtRequiredBy).getTime();
    }
  }

  async created () {
    const data = await this.$store.dispatch('getUpcomingTermsOfService');
    this.upcomingTosModal = data.me.organization.tenant.upcomingTosModal;
    this.loading = false;
  }

  decline () {
    this.$store.commit('setFlash', {
      message: this.$t("You've declined our Terms of Service. Please contact support@springboardvr.com"),
      type: 'info',
    });
  }

  async remindMeLater () {
    this.$store.dispatch('insertEntities', {
      name: 'User',
      payload: {
        id: this.user.id,
        upcomingAgreedAt: 'temporary-agreed-at',
      },
    });
    this.$router.push({ name: 'home' });
    await this.$store.dispatch('initUser', this.$store.getters.currentUser);
  }

  async accept () {
    await this.$store.dispatch('acceptUpcomingTOS');
  }
}
</script>

<style lang="postcss" scoped>
@import "../styles";

.accept-tos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: var(--spacingSm);

  @media (--tablet) {
    padding: var(--spacingMd);
  }

  & img {
    width: 100%;
    max-width: 30rem;
    margin-bottom: var(--spacingSm);
  }

  & .imagecontainer {
    width: 100%;
    max-width: 70rem;
  }

  & .panel {
    width: 100%;
    max-width: 70rem;
  }

  & .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--spacingMd);

    & button {
      padding-left: var(--spacingLg);
      padding-right: var(--spacingLg);
    }

    & button:first-child {
      margin-right: var(--spacingSm);
    }
  }
}
</style>
